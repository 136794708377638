import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import review from './aduform.vue';

formCreate.component('personreview', review);
export default {
  extends: Form,
  data() {
    return {
      formFunctionCode: 'review',
      buttonText: {
        submit: '提交',
        close: '关闭',
      },
      buttons: {
        submit: this.formConfig.code !== 'view',
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  mounted() {

  },

  methods: {
    formComplete() {
      console.log(this.formConfig);
      this.getDetails();
    },
    setRule(item) {
      const v = item;
      console.log(v);
      return v;
    },
    submit() {
      const personreview = this.fApi.el('personreview');
      console.log(personreview);
      const pars = {
        id: this.formConfig.id,
        pilePhotoReqVos: [],
        shelfPhotoReqVos: [],
      };
      personreview.pilePhotoReqVos.forEach((item) => {
        pars.pilePhotoReqVos.push({
          conformStatus: item.conformStatus,
          remarks: item.conformStatus === 'N' ? item.remarks : '',
          taskId: item.taskId,
          executeId: item.executeId,
          id: item.id,
          type: item.type,
          url: item.url,
        });
      });
      personreview.shelfPhotoReqVos.forEach((item) => {
        pars.shelfPhotoReqVos.push({
          conformStatus: item.conformStatus,
          remarks: item.conformStatus === 'N' ? item.remarks : '',
          taskId: item.taskId,
          executeId: item.executeId,
          id: item.id,
          type: item.type,
          url: item.url,
        });
      });
      request.post('/cps/cpsagreedisplay/executeAudit', pars).then((res) => {
        console.log(res);
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },

    async getDetails() {
      await request.post('/cps/cpsagreedisplay/executePhotoQuery', { id: this.formConfig.id }).then((res) => {
        console.log(res);
        if (res.success) {
          console.log(this.formConfig.code);
          // if (res.result.pilePhotoReqVos) {
          //   res.result.pilePhotoReqVos.forEach((item) => {
          //     item.check = '-1';
          //   });
          // }
          // if (res.result.shelfPhotoReqVos) {
          //   res.result.shelfPhotoReqVos.forEach((item) => {
          //     item.check = '-1';
          //   });
          // }
          this.fApi.el('personreview').pilePhotoReqVos = res.result.pilePhotoReqVos;
          this.fApi.el('personreview').shelfPhotoReqVos = res.result.shelfPhotoReqVos;
          this.fApi.el('personreview').formConfig = this.formConfig;
        }
      });
    },
  },
};
