var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-select" }, [
    _c(
      "div",
      { staticClass: "title_row" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("堆头照片")]),
        _vm.formConfig.code != "view"
          ? _c(
              "el-radio-group",
              {
                on: {
                  input: function ($event) {
                    return _vm.getAll($event, "dt")
                  },
                },
                model: {
                  value: _vm.dt,
                  callback: function ($$v) {
                    _vm.dt = $$v
                  },
                  expression: "dt",
                },
              },
              [
                _c("el-radio", { attrs: { label: "1", name: "1" } }, [
                  _vm._v("均达标"),
                ]),
                _c("el-radio", { attrs: { label: "2", name: "2" } }, [
                  _vm._v("均未达标"),
                ]),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dt_info" },
      _vm._l(_vm.pilePhotoReqVos, function (item, index) {
        return _c("div", { key: index, staticClass: "dt_info_item" }, [
          _c(
            "div",
            { staticClass: "image" },
            [
              _c("el-image", {
                attrs: { src: item.url, "preview-src-list": [item.url] },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "item_radio" }, [
            _c(
              "div",
              { staticClass: "radio_g" },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: {
                      value: item.conformStatus,
                      disabled: _vm.formConfig.code == "view",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.handleRadioGroup($event, index, "dt")
                      },
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "Y" } }, [_vm._v("达标")]),
                    _c("el-radio", { attrs: { label: "N" } }, [
                      _vm._v("未达标"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: item.conformStatus == "N",
                    expression: "item.conformStatus == 'N'",
                  },
                ],
                staticClass: "item_input",
              },
              [
                _c("el-input", {
                  attrs: {
                    placeholder: "请输入未达标原因",
                    disabled: _vm.formConfig.code == "view",
                  },
                  model: {
                    value: item.remarks,
                    callback: function ($$v) {
                      _vm.$set(item, "remarks", $$v)
                    },
                    expression: "item.remarks",
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "title_row" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("货架照片")]),
        _vm.formConfig.code != "view"
          ? _c(
              "el-radio-group",
              {
                on: {
                  input: function ($event) {
                    return _vm.getAll($event, "hj")
                  },
                },
                model: {
                  value: _vm.hj,
                  callback: function ($$v) {
                    _vm.hj = $$v
                  },
                  expression: "hj",
                },
              },
              [
                _c("el-radio", { attrs: { label: "1", name: "1" } }, [
                  _vm._v("均达标"),
                ]),
                _c("el-radio", { attrs: { label: "2", name: "2" } }, [
                  _vm._v("均未达标"),
                ]),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dt_info" },
      _vm._l(_vm.shelfPhotoReqVos, function (item, index) {
        return _c("div", { key: index, staticClass: "dt_info_item" }, [
          _c(
            "div",
            { staticClass: "image" },
            [
              _c("el-image", {
                attrs: { src: item.url, "preview-src-list": [item.url] },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item_radio" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: {
                    value: item.conformStatus,
                    disabled: _vm.formConfig.code == "view",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.handleRadioGroup($event, index, "hj")
                    },
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "Y" } }, [_vm._v("达标")]),
                  _c("el-radio", { attrs: { label: "N" } }, [_vm._v("未达标")]),
                ],
                1
              ),
            ],
            1
          ),
          item.conformStatus == "N"
            ? _c(
                "div",
                { staticClass: "item_input" },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入未达标原因",
                      disabled: _vm.formConfig.code == "view",
                    },
                    model: {
                      value: item.remarks,
                      callback: function ($$v) {
                        _vm.$set(item, "remarks", $$v)
                      },
                      expression: "item.remarks",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }