import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import review from '../form/index';

export default {
  extends: TablePage,
  components: {
    review,
  },
  name: 'config_list',
  data() {
    return {
      requestType: 'POST',
    };
  },
  created() {
    this.getConfigList('page_list');
  },

  methods: {
    clickVisible({ row, val: { code } }) {
      if (code === 'review') {
        return row.auditStatus === 'N';
      }
      return true;
    },
    // 禁用
    modalClick({ val, row }) {
      console.log(val);
      console.log(row);
      if (val.code === 'review') {
        console.log('ffff');
        this.formName = 'review';
        this.modalConfig.title = '审核';
        console.log(row.code);
        this.formConfig = {
          code: val.code,
          id: row.id,
        };

        this.openFull();
      }
      if (val.code === 'view') {
        this.formName = 'review';
        this.modalConfig.title = '查看详情';
        console.log(row.code);
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          id: row.id,
        };
        this.openFull();
      }
    },
  },
};
